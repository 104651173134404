import React from "react"
import { Content, Layout, Heading1, SEO, Changelog } from "../components"

const ChangeLog = () => {
  return (
    <Layout>
      <SEO title="Change Log" />
      <Content>
        <Heading1>Change Log</Heading1>
        <Changelog />
      </Content>
    </Layout>
  )
}

export default ChangeLog
